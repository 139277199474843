
import {
    defineComponent,
    ref,
    createVNode,
    computed,
    nextTick,
    onMounted,
} from 'vue';
import { useStore } from 'vuex';
import {
    useRoute,
    onBeforeRouteLeave,
    useRouter,
    RouteLocationNormalizedLoaded,
} from 'vue-router';
import { ColumnsType } from '../types';
import AInput from 'ant-design-vue/lib/input';
import AButton from 'ant-design-vue/lib/button';
import {
    getCheckingOverviewReleaseList,
    getCheckingOverviewReleaseSave,
} from '@/API/salesReporting';
import Decimal from '@/utils/closing/decimal';

/**
 * 限制数字格式为负数
 */
function constraintNegNumber(number: string) {
    number = number.replace(/[^0-9\\-]/g, '');
    if (number === '-') {
        number = '';
    } else if (number !== '' && number !== '0') {
        if (number[0] !== '-') {
            number = `-${number}`;
        }
    }
    return number;
}

export default defineComponent({
    // components: { PlusCircleOutlined },
    setup(props, { emit }) {
        // 前端生成记录需要 e.g. 1 2 3 4 ....
        let id = 1;
        const store = useStore();
        const {
            yearList: years,
            make,
            closeOrNot,
            startYear,
        } = store.state.salesReport.fcSearchParams;
        const globalModified = ref(false);

        //* Direct Subsidy / Payout to dealer / PMT 三列输入单元格渲染
        const renderInputCell = ({ record }: any, type: string) => {
            //* (New Add) 表示固定用于新增数据的行
            // console.log('renderInputCell', record[type], record);
            if (record.rowName === 'Text') {
                return createVNode(AInput, {
                    value: record[type],
                    // type: 'number', //? 设置单元格只能输入数字
                    onInput: ($event: any) => {
                        //TODO 修改历史数据后改变状态
                        record[type] = constraintNegNumber($event.target.value);
                        record.isModified[type] = true;
                        globalModified.value = true;
                    },
                    class: record.isModified[type] ? 'modified' : '',
                });
            }
            if (record.rowName === 't/o to-be released') {
                return createVNode('p', {}, `${record[type]}`);
            }
        };

        const renderYearSumCell = ({ record, index }: any, colName: string) => {
            // console.log(record, '123');
            if (
                record.rowName === 'Text' ||
                record.rowName === 't/o to-be released'
            ) {
                return createVNode('p', {}, `${record[colName]}`);
            }
        };

        //* 根据后台数据 生成全部表头
        //* 表头数据中的 Possible Release 和 t/o already released的全部数据
        const subColumns = {
            'Direct Subsidy': 'directSubsidy',
            'Payout to Dealer': 'payoutToDealer',
            PMT: 'pmt',
        };
        const possibleReleaseRowData =
            store.state.salesReport.possibleReleaseRowData;
        const columnsAll = ref<ColumnsType[]>([]);

        const distinctColor = (column: any, type: string) => {
            return { class: [type] };
        };
        //* 动态生成表列名
        const generateDynamicColumns = () => {
            const initColumns = [];
            for (const year of years) {
                //* Y2021等数据对象 并放入表头数组中
                const yearObj = {
                    // group: 1, //TODO 要不要加个分组信息？
                    cTitle: year,
                    display: true,
                    parent: null,
                    dataIndex: year,
                    key: year,
                    canExpand: true,
                    expandStatus: false,
                    slots: { title: year },
                    customHeaderCell: (column: any) =>
                        distinctColor(column, 'distinct-shallow-blue'),
                    children: [
                        {
                            pr: possibleReleaseRowData[year],
                            slots: { title: `${year}_pr` },
                            customHeaderCell: (column: any) =>
                                distinctColor(column, 'distinct-dark-blue'),
                            children: [
                                {
                                    ar: 0,
                                    slots: { title: `${year}_ar` },
                                    customRender: (rowobj: any) =>
                                        renderYearSumCell(rowobj, year),
                                    customHeaderCell: (column: any) =>
                                        distinctColor(
                                            column,
                                            'distinct-shallow-blue'
                                        ),
                                },
                            ],
                        },
                    ],
                };
                initColumns.push(yearObj);
                //* pmt等数据对象
                for (const [key, value] of Object.entries(subColumns)) {
                    const subObj = {
                        cTitle: key,
                        display: false,
                        parent: year,
                        dataIndex: `${value}_${year}`,
                        key: `${value}_${year}`,
                        canExpand: false,
                        slots: { title: `${value}_${year}` },
                        customHeaderCell: (column: any) =>
                            distinctColor(column, 'distinct-gray'),
                        children: [
                            {
                                slots: { title: `${value}_${year}_pr` },
                                pr: possibleReleaseRowData[`${value}_${year}`],
                                customHeaderCell: (column: any) =>
                                    distinctColor(column, 'distinct-dark-blue'),
                                children: [
                                    {
                                        ar: 0,
                                        slots: { title: `${value}_${year}_ar` },
                                        customHeaderCell: (column: any) =>
                                            distinctColor(
                                                column,
                                                'distinct-shallow-blue'
                                            ),
                                        customRender: (rowobj: any) =>
                                            renderInputCell(
                                                rowobj,
                                                `${value}_${year}`
                                            ),
                                    },
                                ],
                            },
                        ],
                    };
                    initColumns.push(subObj);
                }
            }
            columnsAll.value = initColumns;
        };
        generateDynamicColumns();

        const displayColumnsClick = (parentTitle: string) => {
            columnsAll.value.forEach((col: any) => {
                if (col.parent === parentTitle) {
                    col.display = !col.display;
                }
                if (col.cTitle === parentTitle) {
                    col.expandStatus = !col.expandStatus;
                }
            });
        };
        //* 获取需要display的表头 并塞入第一列
        // const columns = computed(() => {
        //     const cols = columnsAll.value.filter((col: any) => {
        //         return col.display;
        //     });
        //     cols.unshift({
        //         dataIndex: 'rowName',
        //         key: 'rowName',
        //         fixed: 'left',
        //         slots: { title: 'rowName' },
        //         children: [
        //             {
        //                 cTitle: 'Possible Release (Accrual vs. Payout BE)',
        //                 slots: { title: 'rowName2' },
        //                 children: [
        //                     {
        //                         cTitle: 't/o already released (+)',
        //                         width: 200,
        //                         dataIndex: 'rowName',
        //                         slots: { title: 'rowName3' },
        //                         customRender: renderRowName,
        //                     },
        //                 ],
        //             },
        //         ],
        //     });
        //     return cols;
        // });

        //* 计算表格宽度
        const colWidth = computed(() => {
            const defaultWidth = 150;
            const len = columnsAll.value.filter((col: any) => {
                return col.display;
            }).length;
            return defaultWidth * len + 200;
        });

        //* 计算表格高度
        const colHeight = ref(500);
        const calculateHeight = () => {
            const table = document.getElementsByClassName(
                'ant-table-wrapper'
            )[0];
            const clinetHeight = document.body.clientHeight;
            const top = table.getBoundingClientRect().top;
            colHeight.value = clinetHeight - top - 150;
        };

        nextTick(() => {
            calculateHeight();
        });

        //TODO 初始化(New Add)行
        const createFirstRowObj = () => {
            const firstObj: any = { rowName: '(New Add)', isModified: {} };
            for (const year of years) {
                firstObj[`${year}`] = 0;
                for (const [key, value] of Object.entries(subColumns)) {
                    firstObj[`${value}_${year}`] = 0;
                }
            }
            return firstObj;
        };

        const createLastRowObj = () => {
            const lastObj: any = { rowName: 't/o to-be released' };
            for (const year of years) {
                lastObj[`${year}`] = 0;
            }
            return lastObj;
        };

        // 假数据
        const dataSource = ref<any[]>([]);
        const emptyDataSource: any[] = [];
        const firstRowObj = createFirstRowObj();
        const lastRowObj = createLastRowObj();
        // const initDataSource = ref<any[]>([firstRowObj, lastRowObj]);
        const initDataSource = ref<any[]>([]);

        //* 动态生成表格数据
        const generateTableData = (dataList: any) => {
            // const data: any[] = [];
            if (dataList.length > 0) {
                id = parseInt(dataList[0][0]) + 1;
            }
            const columnsNameList: string[] = ['', 'rowName'];
            for (const year of years) {
                columnsNameList.push(`${year}`);
                for (const [key, value] of Object.entries(subColumns)) {
                    columnsNameList.push(`${value}_${year}`);
                }
            }
            const data = dataList.reduce((lst: any, row: any) => {
                const rowData = row.reduce(
                    (obj: any, ele: string, index: number) => {
                        if (index === 0) {
                            return {
                                ...obj,
                                id: ele,
                            };
                        }
                        if (index === 1) {
                            return {
                                ...obj,
                                [columnsNameList[index]]: 'Text',
                                val: ele,
                            };
                        }
                        return {
                            ...obj,
                            [columnsNameList[index]]: ele,
                            isModified: {
                                [columnsNameList[index]]: false,
                            },
                        };
                    },
                    {}
                );
                return [...lst, rowData];
            }, []);
            // console.log('generateTableData', data);
            return data;
        };

        // console.log('initDataSource', initDataSource.value);

        //* 点击加号展开数据
        // const isExpanded = ref(false);
        // const expandData = () => {
        //     isExpanded.value = !isExpanded.value;
        //     if (isExpanded.value) {
        //         dataSource.value = initDataSource.value;
        //     } else {
        //         dataSource.value = emptyDataSource;
        //     }
        // };

        //* 计算表头第三行ar数据时，初始化一个 0值对象
        const initColumnData = () => {
            const colDataObj: any = {};
            for (const year of years) {
                colDataObj[`${year}`] = { [year]: 0 };
                for (const value of Object.values(subColumns)) {
                    colDataObj[`${year}`][`${value}_${year}`] = 0;
                }
            }
            return colDataObj;
        };

        //* 千分位字符串变成数字
        const toNumber = (str: string) => {
            if (str === '') {
                return 0;
            }
            return parseFloat(str.toString().replaceAll(',', ''));
        };

        const calculateArColumns = (children: any) => {
            //* 重新计算 ds ptd pmt 三列表头第三行数据(按列计算)
            const colDataObj = initColumnData();
            children.forEach((row: any) => {
                if (row.rowName === 'Text') {
                    for (const objKey in colDataObj) {
                        for (const key of Object.keys(colDataObj[objKey])) {
                            colDataObj[objKey][key] =
                                toNumber(colDataObj[objKey][key]) +
                                toNumber(row[key]);
                        }
                    }
                }
            });
            //* 计算每一年的表头第三行的ar， 以及尾行t/o to-be released
            for (const year of years) {
                colDataObj[year][year] = 0;
                for (const value of Object.values(subColumns)) {
                    // children[children.length - 1][`${value}_${year}`] = possibleReleaseRowData[`${value}_${year}`]
                    colDataObj[year][year] += toNumber(
                        colDataObj[year][`${value}_${year}`]
                    );
                }
                children[children.length - 1][year] = new Decimal(
                    possibleReleaseRowData[year]
                )
                    .plus(colDataObj[year][year])
                    .round(0);
            }

            columnsAll.value.forEach((col: any) => {
                let val;
                for (const year of years) {
                    if (col.cTitle === year) {
                        val = colDataObj[year][year];
                    } else {
                        for (const [key, value] of Object.entries(subColumns)) {
                            if (col.cTitle === key && col.parent === year) {
                                val = colDataObj[year][`${value}_${year}`];
                                children[children.length - 1][
                                    `${value}_${year}`
                                ] = new Decimal(
                                    possibleReleaseRowData[`${value}_${year}`]
                                )
                                    .plus(val)
                                    .round(0);
                            }
                        }
                    }
                }
                col.children[0].children[0].ar = new Decimal(val).round(0);
            });
        };

        const calculateYearCols = (children: any) => {
            //* 重新计算Y2021等列数据
            children.forEach((row: any) => {
                if (row.rowName === 'Text') {
                    for (const year of years) {
                        row[year] = 0;
                        for (const value of Object.values(subColumns)) {
                            row[year] += toNumber(row[`${value}_${year}`]);
                        }
                        row[year] = new Decimal(row[year]).round(0);
                    }
                }
            });
        };

        const getData = () => {
            const params = {
                make,
                closeOrNot,
                startYear,
                yearList: years,
            };
            getCheckingOverviewReleaseList(params).then((res: any) => {
                const middleData = generateTableData(res.dataList.reverse());
                initDataSource.value = [firstRowObj, ...middleData, lastRowObj];
                calculateArColumns(initDataSource.value);
                calculateYearCols(initDataSource.value);
                dataSource.value = initDataSource.value;
            });
        };

        const resetModifiedFlag = () => {
            initDataSource.value.forEach((data: any) => {
                const modifiedObj = data.isModified;
                if (modifiedObj && Object.keys(modifiedObj).length > 0) {
                    Object.keys(modifiedObj).forEach((key: string) => {
                        modifiedObj[key] = false;
                    });
                }
            });
            globalModified.value = false;
        };

        const save2 = () => {
            const data = JSON.parse(JSON.stringify(initDataSource.value)).slice(
                1,
                initDataSource.value.length - 1
            );

            const dataList = [];
            for (let i = 0; i < data.length; i++) {
                const array = [];
                const row = data[i];
                const { id, val, rowName, isModified, ...rest } = row;
                const keys = Object.keys(rest);
                const yearLength = keys.length / 4;
                array.push(id);
                array.push(val);

                for (let j = 0; j < yearLength; j++) {
                    const directSubsidy = toNumber(
                        row[`directSubsidy_${keys[j]}`]
                    );
                    const payoutToDealer = toNumber(
                        row[`payoutToDealer_${keys[j]}`]
                    );
                    const pmt = toNumber(row[`pmt_${keys[j]}`]);
                    array.push(directSubsidy + payoutToDealer + pmt);
                    array.push(directSubsidy);
                    array.push(payoutToDealer);
                    array.push(pmt);
                }
                dataList.push(array);
            }
            const params = {
                make,
                yearList: years,
                dataList,
            };
            return getCheckingOverviewReleaseSave(params).then((res: any) => {
                resetModifiedFlag();
                const children = dataSource.value as Array<any>;
                calculateArColumns(children);
                calculateYearCols(children);
            });
        };

        //* 新增一行数据
        const save = () => {
            //TODO 需要校验传入的是不是数字
            if (dataSource.value.length > 0) {
                //* 将保存的数据填入数据源中
                const children = dataSource.value as Array<any>;
                const inputRow = children.shift();
                inputRow.rowName = 'Text';
                inputRow.val = '';
                inputRow.id = id++;
                const newFirstRow = createFirstRowObj();
                children.unshift(newFirstRow);
                children.splice(1, 0, inputRow);
                calculateArColumns(children);
                calculateYearCols(children);
            }
        };

        getData();

        const showData = () => {
            console.log('dataSource', dataSource.value);
            console.log('columnsAll', columnsAll.value);
        };

        onMounted(() => {
            window.addEventListener('resize', calculateHeight);
        });

        //* 以下代码实现： 如未点保存退出页面，系统提醒用户：“历史数据已修改，请点击保存退出。”如果用户不点击保存直接退出，修改后的数据不记录；
        const router = useRouter();
        const toRoute = ref<RouteLocationNormalizedLoaded>();
        const hasForceExit = ref(false);
        const warningVisible = ref(false);

        const goBack = () => {
            router.go(-1);
        };

        const forceExit = () => {
            hasForceExit.value = true;
            router.push(toRoute.value as RouteLocationNormalizedLoaded);
        };

        const saveForExit = () => {
            warningVisible.value = false;
            save2().then((_: any) => {
                router.push(toRoute.value as RouteLocationNormalizedLoaded);
            });
        };

        onBeforeRouteLeave((to) => {
            if (hasForceExit.value) {
                return true;
            }
            if (globalModified.value) {
                toRoute.value = to;
                warningVisible.value = true;
                return false;
            } else {
                return true;
            }
        });

        //* 表格第一列 固定行的行头渲染逻辑
        const renderRowName = ({ record }: any) => {
            const obj = {
                children: record.rowName,
                props: {} as any,
            };
            if (record.rowName === '(New Add)') {
                // return createVNode('p', {}, `${record.rowName}`);
                return createVNode(
                    AButton,
                    { onClick: save },
                    { default: () => `${record.rowName}` }
                );
            }
            if (record.rowName === 'Text') {
                return createVNode(AInput, {
                    value: record.val,
                    onInput: ($event: any) => {
                        record.val = $event.target.value;
                    },
                });
            }
            return obj;
        };

        const columns = computed(() => {
            const cols = columnsAll.value.filter((col: any) => {
                return col.display;
            });
            cols.unshift({
                dataIndex: 'rowName',
                key: 'rowName',
                fixed: 'left',
                slots: { title: 'rowName' },
                customHeaderCell: (column: any) =>
                    distinctColor(column, 'distinct-shallow-blue'),
                children: [
                    {
                        cTitle: 'Possible Release (Accrual vs. Payout BE)',
                        slots: { title: 'rowName2' },
                        customHeaderCell: (column: any) =>
                            distinctColor(column, 'distinct-dark-blue'),
                        children: [
                            {
                                cTitle: 't/o already released (+)',
                                width: 200,
                                dataIndex: 'rowName',
                                slots: { title: 'rowName3' },
                                customRender: renderRowName,
                                customHeaderCell: (column: any) =>
                                    distinctColor(
                                        column,
                                        'distinct-shallow-blue'
                                    ),
                            },
                        ],
                    },
                ],
            });
            return cols;
        });

        //* 动态计算 表头第二行 用于设置插槽 来绑定pr数据
        const columnsSons = computed(() => {
            const sonList = columns.value.reduce((sons: any, father: any) => {
                return [...sons, ...father.children];
            }, []);
            return sonList;
        });
        //* 动态计算 表头第三行 用于设置插槽 来绑定ar数据
        const columnsGrandsons = computed(() => {
            const sonList = columnsSons.value.reduce(
                (sons: any, father: any) => {
                    return [...sons, ...father.children];
                },
                []
            );
            return sonList;
        });

        const customHeaderRow = (column: any, index: any) => {
            if (index === 1) {
                return {
                    class: 'second-table-header',
                };
            }
        };

        const totalRowClass = (record: any) => {
            const weights = ['t/o to-be released'];
            if (weights.includes(record.rowName)) {
                return 'last-row';
            }
        };

        return {
            renderRowName,
            dataSource,
            columns,
            colWidth,
            colHeight,
            displayColumnsClick,
            showData,
            save,
            // isExpanded,
            // expandData,
            columnsSons,
            columnsGrandsons,
            save2,
            globalModified,
            forceExit,
            saveForExit,
            warningVisible,
            goBack,
            customHeaderRow,
            totalRowClass,
        };
    },
});
